import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { rgba } from 'emotion-rgba';
import { colors } from '@styles/ui_palette';
import { useForm } from 'react-hook-form';
import { IBurnCard } from '@pages/BurnList';
import { Axios, CaverOption } from '@utils/api';
import { Store } from 'react-notifications-component';

// Components
import Input from '@components/common/ui/Input';
import { getSupplyContractABI } from '@utils/help';

const Caver = require('caver-js');

const caver = new Caver(
    new Caver.providers.HttpProvider('https://node-api.klaytnapi.com/v1/klaytn', CaverOption)
);

type MarketsData = {
    ca: string;
    offset_date: string;
    sig_code: string;
};

interface IRModal {
    setBurn: any;
    burn: IBurnCard | null;
}

function RequestModal({ burn, setBurn }: IRModal) {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { isValid, errors },
    } = useForm<MarketsData>({
        mode: 'onChange',
    });

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        const setData = {
            ...burn,
            ...data,
        };

        const getTxId = await setTransaction(setData);

        if (getTxId && getTxId !== '') {
            submitDB(setData, getTxId);
        }
    };

    const sendSingleTransaction = async (assets: IBurnCard) => {
        const senderPrivateKey = process.env.REACT_APP_FEE_PRIVATE_KEY;
        const keyring = caver.wallet.keyring.createFromPrivateKey(senderPrivateKey);
        caver.wallet.add(keyring);
        caver.klay.accounts.wallet.add(
            caver.klay.accounts.createWithAccountKey(keyring.address, keyring.key.privateKey)
        );
        const myContract = new caver.klay.Contract(
            getSupplyContractABI(1).abi,
            process.env.REACT_APP_KLAYTN_SINGLE_CONTRACT_ADDRESS
        );

        try {
            const tx = await myContract.methods.setBurn(assets.token_id).send({
                from: keyring.address,
                gas: 6000000,
            });

            return tx.transactionHash;
        } catch (error) {
            return null;
        }
    };

    const sendMultiTransaction = async (assets: IBurnCard) => {
        console.log('1');
        const senderPrivateKey = process.env.REACT_APP_FEE_PRIVATE_KEY;
        const keyring = caver.wallet.keyring.createFromPrivateKey(senderPrivateKey);
        console.log('2');
        caver.wallet.add(keyring);
        caver.klay.accounts.wallet.add(
            caver.klay.accounts.createWithAccountKey(keyring.address, keyring.key.privateKey)
        );
        console.log('3');
        const myContract = new caver.klay.Contract(
            getSupplyContractABI(2).abi,
            process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS
        );
        console.log('4');
        const tx = await myContract.methods
            .setBurn(assets.owner_address, assets.token_id, assets.offset_qty)
            .send({
                from: keyring.address,
                gas: 6000000,
            });

        console.log('5', tx);

        return tx.transactionHash;
    };

    const setTransaction = useCallback(async (setData: IBurnCard) => {
        let txId: string;

        if (
            setData.contract_address.toLocaleLowerCase() ===
            process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS?.toLocaleLowerCase()
        ) {
            console.log('시작2');
            txId = await sendMultiTransaction(setData);
            return txId;
        }

        console.log('안돼');

        txId = await sendSingleTransaction(setData);

        return txId;
    }, []);

    const submitDB = useCallback(async (setData: IBurnCard, getTxId: string) => {
        const token = `Bearer ${localStorage.getItem('token')}` || '';

        const formData = new FormData();

        formData.append('wallet_address', '');
        formData.append('blockchain', 'klaytn');

        formData.append('offset_req_id', setData.id.toString());
        formData.append('asset_id', setData.asset_id.toString());
        formData.append('cer_name', setData.cer_name);
        formData.append('offset_qty', setData.offset_qty.toString());
        formData.append('offset_name', setData.offset_name);
        formData.append('tx_id', getTxId);
        formData.append('offset_date', setData.offset_date);
        formData.append('sig_code', setData.sig_code);
        formData.append('ca', setData.ca);

        try {
            const { success } = await Axios('offset_req_proc', formData, token);

            if (success) {
                setLoading(false);
                setBurn(null);
                window.location.reload();

                Store.addNotification({
                    ...notiOption,
                    title: '완료',
                    message: '상쇄를 완료하였습니다.',
                    type: 'default',
                    container: 'top-left',
                    insert: 'top',
                });
            }
        } catch (error) {
            setLoading(false);
            setBurn(null);

            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '개발팀에 문의해주세요.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <ModalContainer>
            <ModalBox>
                <ModalTitle>
                    상쇄하기
                    <Close src="/img/common/ic_qr_modal_close.svg" onClick={() => setBurn(null)} />
                </ModalTitle>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Sections>
                        <InputContainer>
                            <Title>인증기관</Title>
                            <Input
                                register={register}
                                required
                                minLength={1}
                                maxLength={100}
                                valueLength={watch('ca') ? watch('ca').length : 0}
                                getValue={getValues('ca')}
                                setValue={setValue}
                                error={!!errors.ca}
                                placeholder="인증기관을 입력해주세요."
                                errorMessage="인증기관을 입력해주세요."
                                label="ca"
                                type="text"
                            />
                        </InputContainer>
                        <InputContainer>
                            <Title>인증번호</Title>
                            <Input
                                register={register}
                                required
                                minLength={1}
                                maxLength={100}
                                valueLength={watch('sig_code') ? watch('sig_code').length : 0}
                                getValue={getValues('sig_code')}
                                setValue={setValue}
                                error={!!errors.sig_code}
                                placeholder="인즌번호 입력해주세요."
                                errorMessage="인즌번호 입력해주세요."
                                label="sig_code"
                                type="text"
                            />
                        </InputContainer>

                        <InputContainer>
                            <Title>인증날짜</Title>
                            <Input
                                register={register}
                                required
                                minLength={1}
                                maxLength={100}
                                valueLength={watch('offset_date') ? watch('offset_date').length : 0}
                                getValue={getValues('offset_date')}
                                setValue={setValue}
                                error={!!errors.offset_date}
                                placeholder="인증 날짜 입력해주세요."
                                errorMessage="인증 날짜 입력해주세요."
                                label="offset_date"
                                type="text"
                            />
                        </InputContainer>
                    </Sections>

                    <Submit
                        type="submit"
                        value={loading ? '상쇄중..기다려주세요' : 'NFT 상쇄하기'}
                        disabled={!isValid || loading}
                    />
                </Form>
            </ModalBox>
        </ModalContainer>
    );
}

const Close = styled('img')`
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    cursor: pointer;
`;

const ModalContainer = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba(colors.Black200, 0.8)};
    z-index: 99999;
`;

const ModalBox = styled.div`
    width: 500px;
    height: 540px;
    background-color: ${colors.White100};
    border-radius: 12px;
    padding: 0 30px;
`;

const ModalTitle = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.Black100};
    border-bottom: 1px solid ${colors.BlueGray400};
    position: relative;
`;

const Form = styled.form``;

const Sections = styled.div`
    margin: 30px 0;
`;

const InputContainer = styled.div`
    margin: 10px 0;
    flex-basis: 100%;
`;

const Title = styled.div`
    font-size: 14px;
    color: ${rgba(colors.Black100, 0.9)};
    font-weight: 500;
    height: 36px;
    margin-top: 30px;

    & div {
        font-size: 12px;
        color: ${rgba(colors.Black100, 0.6)};
        margin-top: 5px;
    }
`;

const Submit = styled.input`
    width: 100%;
    height: 58px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    background-color: ${colors.Black200};
    color: ${colors.White100};
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    &:disabled {
        background-color: ${colors.BlueGray300};
        color: ${colors.BlueGray700};
        cursor: not-allowed;
    }
`;

export default RequestModal;

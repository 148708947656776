import React from 'react';
import styled from '@emotion/styled/macro';
import { colors, fonts } from '@styles/ui_palette';
import { Store } from 'react-notifications-component';
import { useRecoilState } from 'recoil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { prepare } from 'klip-sdk';
import { Axios, loginExpire, getSignResult, CaverOption } from '@utils/api';
import { checkUserInfo } from '@utils/help';

import qs from 'qs';

// recoil
import { UserAddress, KlipKey } from '@recoil/auth';
import { rgba } from 'emotion-rgba';

const Caver = require('caver-js');

function Login() {
    const { t } = useTranslation();
    const [, setRequestKey] = useRecoilState<string>(KlipKey);
    const [, setUserAddress] = useRecoilState(UserAddress);
    const [searchParams] = useSearchParams();
    const location = searchParams.get('location');
    const caver = new Caver(
        new Caver.providers.HttpProvider('https://node-api.klaytnapi.com/v1/klaytn', CaverOption)
    );
    const navigate = useNavigate();

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
        },
    };

    const handdleLogin = async () => {
        try {
            const reseponse = await prepare.auth({
                bappName: 'Denaissance',
            });

            if (reseponse.request_key && reseponse.request_key !== '') {
                setRequestKey(reseponse.request_key);

                const timerId = setInterval(async () => {
                    try {
                        const { data } = await getSignResult(reseponse.request_key);

                        if (data.result) {
                            const getAddress = data.result.klaytn_address.toLowerCase();
                            const requestKey = reseponse.request_key;

                            // LOGIN PRAMS
                            const params = qs.stringify({
                                wallet_address: getAddress,
                                blockchain: 'klaytn',
                                signature: requestKey,
                                expire: loginExpire,
                            });

                            const balance = await caver.klay.getBalance(getAddress);

                            setRequestKey('');
                            clearInterval(timerId);

                            // LOGIN API
                            try {
                                const { data } = await Axios('register', params);

                                localStorage.setItem('loginState', params);
                                localStorage.setItem('token', data.token);
                                const isCheckUserInfo = checkUserInfo(data);

                                setUserAddress({
                                    address: getAddress,
                                    status: 'succese',
                                    balance: caver.utils.convertFromPeb(balance, 'KLAY'),
                                    token: data.token,
                                    user_name: data.user_name,
                                    user_email: data.user_email,
                                    image_profile: data.image_profile,
                                    vip_gb: data.vip_gb.toString(),
                                    shipping_name: data.shipping_name,
                                    phone_num: data.phone_num,
                                    shipping_addr: data.shipping_addr,
                                    isCertification: isCheckUserInfo,
                                    adult: data.adult,
                                    birth: data.birth,
                                    name: data.name,
                                    foreigner: data.foreigner,
                                    gender: data.gender,
                                });

                                Store.addNotification({
                                    ...notiOption,
                                    title: t('Notification.notiTitle'),
                                    message: t('Notification.loginsuccese'),
                                    type: 'default',
                                    insert: 'top',
                                    container: 'top-left',
                                });
                                navigate(location || '/');

                                return;
                            } catch (error) {
                                setUserAddress({
                                    address: '',
                                    status: 'error',
                                    balance: '',
                                    token: '',
                                    user_name: '',
                                    user_email: '',
                                    image_profile: '',
                                    vip_gb: '0',
                                    shipping_name: '',
                                    phone_num: '',
                                    shipping_addr: '',
                                    isCertification: false,
                                    adult: 0,
                                    birth: '',
                                    name: '',
                                    foreigner: 0,
                                    gender: 0,
                                });
                            }
                        }
                    } catch (error) {
                        Store.addNotification({
                            ...notiOption,
                            title: t('Notification.errorTitle'),
                            message: t('Notification.cancelSign'),
                            type: 'danger',
                            container: 'top-left',
                            insert: 'top',
                        });
                        setRequestKey('');
                        clearInterval(timerId);
                    }
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container>
            <LoginBox>
                <Title>{t('Login.title')}</Title>
                <Description>클립지갑 서명 후 탄탄의 다양한 서비스를 체험해 보세요.</Description>
                <LoginCard>
                    <ImageWrap>
                        <MetamaskLogo src="/img/login/klip_logo.svg" width={120} alt="클립 로고" />
                    </ImageWrap>
                    <LoginButton onClick={handdleLogin}>클립으로 로그인하기</LoginButton>
                </LoginCard>
            </LoginBox>
        </Container>
    );
}

const MetamaskLogo = styled('img')``;

const Container = styled.div`
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoginBox = styled.div`
    text-align: center;
`;

const Title = styled.div`
    font-family: ${fonts.Tinos};
    font-size: 38px;
    color: ${colors.Black100};
`;

const Description = styled.div`
    font-size: 16px;
    color: ${colors.Black100};
    margin-top: 24px;
`;

const LoginCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #3382ff;
    border-radius: 24px;
    width: 300px;
    height: 400px;
    margin: 0 auto;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    padding: 36px 20px;
    margin-top: 60px;
`;

const ImageWrap = styled.div`
    margin-top: 85px;
    text-align: center;
`;

const LoginButton = styled.div`
    margin-top: auto;
    background-color: ${rgba(colors.White100, 0.1)};
    border-radius: 8px;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: ${colors.White100};
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
        background-image: url('/img/login/ic_login_arrow.png');
        width: 32px;
        height: 22px;
        content: '';
        transition: all 0.2s ease;
        display: block;
    }

    &:hover {
        transition: all 0.2s ease;
        color: #3382ff;
        background-color: ${colors.White100};

        &::after {
            background-image: url('/img/login/ic_login_arrow_hover.png');
            transition: all 0.2s ease;
        }
    }
`;

export default Login;

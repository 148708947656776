import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { useRecoilValue } from 'recoil';
import { UserAddress } from '@recoil/auth';
import { Axios } from '@utils/api';
import { Store } from 'react-notifications-component';
import qs from 'qs';
import RequestModal from '@components/layout/RequestModal';

export interface IBurnCard {
    asset_id: number;
    ca: string; // 인증기관
    cer_name: string; // 배출권 제목
    id: number;
    offset_date: string;
    offset_juno: string;
    offset_name: string;
    offset_qty: number;
    offset_use: string;
    sig_code: string;
    state: number;
    contract_address: string;
    owner_address: string;
    token_id: string;
    created_at: string;
}

export interface IPaginationPrams {
    current_page: number;
    data: any;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: any;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
}

function BurnList() {
    const userAddress = useRecoilValue(UserAddress);
    const [nfts, setNft] = useState<IBurnCard[]>([]);
    const [pagination, setPagination] = useState<number>(1);
    const [pagenationInfo, setPagenationInfo] = useState<IPaginationPrams | null>(null);
    const [burn, setBurn] = useState<IBurnCard | null>();

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const loadBurnNFTs = async () => {
        // 로그인 토큰 가져오기
        const token = `Bearer ${localStorage.getItem('token')}` || '';
        const formData = new FormData();

        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        formData.append('wallet_address', userAddress.address);
        formData.append('co_gb', 'all_req');

        if (getParams.page) {
            setPagination(Number(getParams.page));
        }

        try {
            const { data, success } = await Axios(
                `offset_req_list?page=${getParams.page || 1}`,
                formData,
                token
            );

            if (success && data !== '') {
                const getData: IBurnCard[] | null =
                    Object.values(data.offset_req_list_data) || null;
                if (getData) {
                    await setPagenationInfo(data.pagination_info);
                    setNft(getData);
                }
            } else {
                setNft([]);
            }
        } catch (error) {
            setNft([]);
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '상쇄 리스트를 불러오지 못했습니다.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    useEffect(() => {
        loadBurnNFTs();
    }, []);
    return (
        <>
            <PageTitle>
                NFT 상쇄 신청 리스트
                <PageSub>NFT 상쇄 신청한 유저.</PageSub>
            </PageTitle>
            <Container>
                <ListHeadGroup>
                    <ListItems>
                        <ItemText width={5}>아이디</ItemText>
                        <ItemText width={20}>프로젝트명</ItemText>
                        <ItemText width={20}>생년월일 / 사업자번호</ItemText>
                        <ItemText width={5}>상쇄자명</ItemText>
                        <ItemText width={5}>상쇄수량</ItemText>
                        <ItemText width={20}>상쇄이유</ItemText>
                        <ItemText width={10}>신청날짜</ItemText>
                        <ItemText width={15}>신청여부</ItemText>
                    </ListItems>
                </ListHeadGroup>
                <ListGroup>
                    {nfts.length > 0 &&
                        nfts.map((nft) => (
                            <ListItems key={nft.id}>
                                <ItemText width={5}>{nft.id}</ItemText>
                                <ItemText width={20}>{nft.cer_name}</ItemText>
                                <ItemText width={20}>{nft.offset_juno}</ItemText>
                                <ItemText width={5}>{nft.offset_name}</ItemText>
                                <ItemText width={5}>{nft.offset_qty} TON</ItemText>
                                <ItemText width={20}>{nft.offset_use}</ItemText>
                                <ItemText width={10}>{nft.created_at}</ItemText>
                                <ItemText width={15}>
                                    <Button onClick={() => setBurn(nft)}>신청</Button>
                                </ItemText>
                            </ListItems>
                        ))}
                </ListGroup>
            </Container>

            {burn && <RequestModal setBurn={setBurn} burn={burn} />}
        </>
    );
}

const Container = styled.div`
    border: 1px solid ${colors.BlueGray500};
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin-top: 40px;
`;

const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.Black100};
    margin-bottom: 20px;
`;

const PageSub = styled.div`
    font-size: 13px;
    color: ${colors.Black100};
    font-weight: 400;
    margin-top: 8px;
`;

const ListHeadGroup = styled.div`
    background-color: ${colors.BlueGray350};
`;

const ListGroup = styled.div`
    background-color: ${colors.White100};
`;

const ListItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.BlueGray600};
    padding: 15px 20px;

    &:last-child {
        border: 0;
    }
`;

const ItemText = styled.div<{ width: number }>`
    width: ${(props) => `${props.width}%`};
    text-align: center;
    line-height: 1.5;
`;

const Button = styled.button`
    width: 80px;
    height: 38px;
    background-color: ${colors.White100};
    border: 1px solid ${colors.BlueGray500};
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.BlueGray350};
    }
`;

export default BurnList;

import React, { useMemo, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { Route, Routes, useLocation } from 'react-router-dom';

import Layouts from '@components/layout/Layouts';
import Home from '@pages/Home';
import Error404 from '@pages/404';

import { I18nextProvider } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Login from '@pages/Login';
import MarketCancel from '@pages/MarketCancel';
import MarketRestore from '@pages/MarketRestore';
import AirDrop from '@pages/AirDrop';
import TransactionRestore from '@pages/TransactionRestore';
import BurnList from '@pages/BurnList';
import BurnCompleteList from '@pages/BurnCompleteList';

import { createI18n } from './locales/i18n';

declare global {
    interface Window {
        IMP: any;
    }
}

function App() {
    const i18n = useMemo(() => createI18n({ locale: 'ko' }), []);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (isMobile && window.location.hostname !== 'localhost') {
            window.location.assign(`//m.${window.location.host}`);
        }
    }, []);

    return (
        <RecoilRoot>
            <I18nextProvider i18n={i18n}>
                <Routes>
                    <Route element={<Layouts />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/market-cancel" element={<MarketCancel />} />
                        <Route path="/market-restore" element={<MarketRestore />} />
                        <Route path="/airdrop" element={<AirDrop />} />
                        <Route path="/transaction-restore" element={<TransactionRestore />} />
                        <Route path="/burn-list" element={<BurnList />} />
                        <Route path="/burn-complete" element={<BurnCompleteList />} />
                        {/* <Route
                            path="/token-managements"
                            element={<TokenManagements />}
                        /> */}
                        <Route path="/*" element={<Error404 />} />
                    </Route>
                </Routes>
            </I18nextProvider>
        </RecoilRoot>
    );
}

export default App;
